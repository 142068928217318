// eslint-disable-next-line
import React, { Component } from 'react';
import './css/App.css';
import './css/Hamburger.css';
import {BrowserRouter as Router,Routes, Link, Route} from 'react-router-dom'
import {firebase,pgText,Image,join,getParam,isAdmin} from './helper';
//import join from 'join-path';
import MediaModal from './components/media';
import Login from './components/Login.js';
import Loading from './components/Loading.js';
import Pages from './components/Pages.js';
import UserSettings from './components/actions/EditUserSettings.js';
import {connect} from 'react-redux';
import EditMembers from './components/actions/EditMembersPage.js';
const {set, db, ref, update,onValue, remove} = firebase.database;

class Prompt extends Component{

    slugify(path, from, to){
        /* this need some work
        when update i have to delete the old data */
        const oldPath = ref(db, path + "/" + from);
        const newPath = ref(db, path + "/" + to);
        let done = 0;
        onValue(oldPath, (snapshot) => {
            if(done ==0){
                if(oldPath !== newPath){
                    let data = snapshot.val();
                    if(data){
                        data.slug = to;   
                        update(newPath, data); 
                    }
                }
            }
        });
    }
    
    getPath(path,slice){
        const parts = path.split("/");
        let str = "";
        let l   = parts.length;
        let t   = slice;
        if(slice < 0){
            return parts.slice(slice)[0];
        }
        parts.map((val,i) =>{
            if(l > t){
                str += i ===0? val : `/${val}`;
            }
            l--;
            return null;
        });
        return str;

    }
    updateField(prompt){
        const spchecker = /\S/.test(prompt.value);
        if(prompt.value && spchecker===true || prompt.name =="navigation"){
            /* ADD FIREBASE INJECT HERE */
            if(prompt.page==="pages" && prompt.name==="slug"){
                let path=prompt.path.split("/");
                const new_slug = prompt.value;
                const old_slug = path[7];
                const newPath = `/${path[2]}/${path[3]}/${path[4]}/${path[5]}/${path[6]}`;
                this.slugify(newPath,old_slug,new_slug);
            }else{
                set(ref(db, prompt.path),prompt.value);
            }
        }else{
            // This will empty the value
            set(ref(db, prompt.path),prompt.value);
        }
    }
    onKeyDown(e){
        if(e.key ==="Enter"){
            const submit = this.refs.submit;
            submit.click();
        }
    }
    render(){
        /*
          for input send playload Name=input or empty
          option= selec
        */
        const FieldBox = ()=>{
            const dispatch = (e) =>{
                this.props.dispatch({type:"ONCHANGE_PROMPT",payload:{value:e.target.value}});
               
            }
            const options  = this.props.prompt.options;
            const value = this.props.prompt.value;
            const type = this.props.prompt.type;
            const name  = this.props.prompt.name;
            const isArray = Array.isArray(options);
          
            /* if payload send options we will return select to select only 1 */
            if(this.props.prompt.choose > 0 || name==="select" || type==="select"){
                if(typeof options !== "object") return null;
                return <div className="select">
                        <select id="focusField" onKeyDown={this.onKeyDown.bind(this)} 
                            onChange={(e)=> dispatch(e)} 
                            defaultValue={value}>
                                <option value=""> ** Undefined **</option>
                                {isArray ===true ?
                                    options.map((val, i)=>{
                                        return  <option key={i} value={val}>{val}</option>
                                    }):
                                    Object.keys(options).map((option, i)=>{
                                        return  <option key={i} value={option}>{options[option]}</option>
                                    })
                                } 
                        </select>
                </div>
            }
            
            switch(name){
                case 'content':
                    return <textarea
                        id="focusField"  
                        style={{height:"60vh"}}
                        onChange={(e)=> dispatch(e)} 
                        className="textarea"
                        defaultValue={value}
                      
                        ></textarea>
                case 'description':
                    return <textarea 
                        id="focusField" 
                        style={{height:"60vh"}}
                        onChange={(e)=> dispatch(e)} 
                        className="textarea"
                        defaultValue={value}
                        ></textarea>
                default:
                    return <input 
                        id="focusField" 
                        onKeyDown={this.onKeyDown.bind(this)} 
                        className="input" onChange={(e)=> dispatch(e)} 
                        defaultValue={value}/>
       
            }
        }
        if(this.props.prompt.active){
            return  <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-content"  style={{width:"65%"}}>
                    <div className="modal-section is-white">
                        {this.props.prompt.name==="image"?
                            <div className="field">
                            <img alt="Selected" src={Image(this.props.prompt.value)} className="is-fullwidth"/>
                            <p className="buttons">
                                {this.props.prompt.name ==="image"?
                                 <a className="button" onClick={()=> this.props.dispatch({type:"MEDIA_TOGGLE",media_target:this.props.prompt.name, media_select:true})}>Select image</a>:null}
                            </p>
                            </div>:null}
                        <div className="field">
                            <label className="label">{this.props.prompt.name}:</label>
                            <FieldBox/>
                         </div>
                        <div className="field">
                            <p className="buttons">
                                <button onClick={()=>this.props.dispatch({type:"CLOSE_PROMPT"})} className="button r-10">Cancel</button>
                                <button ref="submit" onClick={this.updateField.bind(this, this.props.prompt)} className="button is-primary">Update</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        }else{
            return null;
        }
    } 
}

class App extends Component {
   
    publishApp(app){
        if(!this.props.settings.username){
            alert("Username not found!, go to settings > username and choose you username");
            return false;
        }
        const pathFrom = join('users',this.props.user,'apps', app);
        const pathTo = join('public',this.props.settings.username);
        

        return onValue(ref(db,pathFrom), (snap) => {
            let val = snap.val();
            const settings = this.props.data[app].settings || null;
            if(val){
                Object.keys(pgText).map((pg,i)=>{
                    if(pg==="gallery" || pg ==="contact"){
                        const p = pg;
                        if(typeof settings.pages[p] === "object"){
                            const slug = settings.pages[p].slug;
                            val.pages[slug] = val.pages[p];
                            val.pages[slug].style = pg;
                            if(slug !==pg){
                                delete val.pages[p];
                            }
                        }
                    }
                    if(pg==="catalog"){
                        const p = pg;
                        const slug = settings.pages[p].slug;
                         val.pages[slug] = val.pages[p];
                         val.pages[slug].name =  settings.pages[p].name;
                         val.pages[slug].slug = slug;
                         val.pages[slug].title = settings.pages[p].name;
                         val.pages[slug].style = "catalog";
                        if(slug !==pg){
                            delete val.pages[p];
                        }
                        // this code will let the client to change catalog name
                        // and slug to any name store, rent, menu etc
                        // CATALOG DEPRECATED
                    }else{
                        if(pgText[pg].public ===false){
                            // this code will delete 
                            // any private data from public pages
                            delete val.pages[pg]
                        }
                    }
                    if(Object.keys(pgText).length === i+1){
                        // Remove from plubic folder
                        ///parefTo.set();
                        set(ref(db, pathTo ),{pages:val.pages,navigation:val.navigation,settings:val.settings});
                        alert("Published success!");
                    } 
                    return null;
                });
            }else{
                alert(val)
            }
          }, {
            onlyOnce: true
        });
    }
    logout(){
        this.props.dispatch({type:"USER_LOGOUT"});
    }
    componentDidMount(){
        const {dispatch} = this.props;
        this.props.dispatch({type:"FETCH_DATA",dispatch:dispatch});
    }
    isApp(props){
        const appid = getParam("app");
        if(this.props.fetched){
            if(!appid || this.props.data[appid]) return true;
            return false;
        }
    }
    goBack(){
        window.history.go(-1);
    }
    render() {
        const GoBackButton = (props)=>{
            return <a  className="back-button" onClick={this.goBack} > <i className="fa fa-arrow-alt-circle-left"></i></a> 
        }
        const PublishButton = (props)=>{
            if(!this.isApp(props)) return null;
            if(this.props.data && getParam("app")){
                return <a className="button is-success" onClick={this.publishApp.bind(this,getParam("app"))}>
                    <span className="icon"><i className="fa fa-upload "></i></span>
                    <span>Publish updates</span>
                </a>
            }
            return null;
        }
        const Aside = (props) =>{
            return <div className="menu-wrap">
                      <ul className="menu">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/settings">Settings</Link> </li>
                        {isAdmin(this.props.settings)?
                            <li><Link to="/members">Members</Link> </li>:null}
                        <li><a onClick={()=>this.props.dispatch({type:"MEDIA_TOGGLE"})}>Media</a> </li>
                        <li><a onClick={this.logout.bind(this)}>Logout</a> </li>
                        </ul>
                        <div className="pub-btn">
                             <PublishButton {...props}/>
                        </div>
                </div> 
        }
        const Home = (props)=>{ 
            const appid = getParam("app");
            let _props = this.props;
            if(appid){
                const appData = this.props.data[appid];
                _props = {
                    history:props.history,
                    location:props.location,
                    dispatch:this.props.dispatch,
                    data:appData,
                    media:this.props.media,
                    path: join('users',this.props.user,'apps', appid),
                    app:appid,
                    settings:this.props.settings,
                    page:getParam("page"),
                    subpage:getParam("subpage"),
                    item:getParam("item"),
                    media_toggle: this.props.media_toggle,
                    media_select: this.props.media_select,
                    media_target: this.props.media_target,
                    form: this.props.form, // selected item
                    user:this.props.user,
                    prompt: this.props.prompt
                }
                
            }   
            return  <div className="full-width">
                        <Prompt {...this.props}/>
                        <MediaModal  {..._props}/>
                        <Pages {..._props} />
                    </div>
     
        }
       
        const ValidateRoot = (props)=>{
            if(!this.props.fetched){
                return <div>Fetching user data...</div>;
            } 
            if(getParam("app") ==="settings"){
                return  <div className="main">
                    <GoBackButton {...props}/>
                    <Aside {...props}/>
                    <UserSettings {...this.props}/>
                </div>
            }
            if(getParam("app") ==="members"){
                return  <div className="main">
                    <GoBackButton {...props}/>
                    <Aside {...props}/>
                    <EditMembers {...this.props}/>
                </div>
            }
            if( !this.isApp(props) && getParam("app") !=="settings"  ){
                return  <div className="main">
                    <GoBackButton {...props}/>
                    <Aside {...props}/>
                    <div> App dosnt exists</div>
                </div>
            }else{
                return  <div className="main">
                    <GoBackButton {...props}/>
                    <Aside {...props}/>
                    <Home {...props}/>
                </div> 
            }
        }

        if(this.props.fetched === false) {
            return <Loading />;
        }else{
           
            if(this.props.user === null){
                return  <Login />
            }else{
        
                return <div>
                    <Router>
                        <Routes>
                            <Route path="/" element={<ValidateRoot/>}  indx="1"/>
                            <Route path="/:app" element={<ValidateRoot />}  indx="2"/>
                            <Route path="/:app/:page" element={<ValidateRoot/>}  indx="3"/>
                            <Route path="/:app/:page/:subpage" exact element={<ValidateRoot indx="4"/>}/>
                            <Route path="/:app/:page/:subpage/:item" exact element={<ValidateRoot indx="4"/>}/>
                        </Routes>
                    </Router>
                </div> 
            }  
        }               
  }
}
const mapStateToProps = (state, props)=>{
    return {...state,...props}
}

export default connect(mapStateToProps)(App);

