const listGeneratorNum = (t)=>{
    let arr = [];
    for (var j=0; j < t; j++) {
        arr.push(t-j-1);
    };
    return arr;
}
const listGenerator = (name, t, fields)=>{
    let l = [];
    for (var j=0; j < t; j++) {
        let ll = {};
        ll.name = j + '_image';
        ll.label = `${name} #${(j+1)}`;
        ll.type = "image";
        ll.fields  = [];
        if(typeof fields ==="object"){
            // eslint-disable-next-line 
            Object.keys(fields).map((field)=>{
                ll.fields.push({name:`${j}_${field}`,label:fields[field]});
                return null;
            });
        }  
        l.push(ll);
        // code to be executed
    }
    return l;
}
export const app_settings_fields = {
    "home":{
        name:"Home",
        icon:"fa fa-home",
        list:listGenerator("Article", 4 ,{
            title:"Title",
            description:"Description",
            link:"Link",
            text:"Button Text",
            color: "Color"
        })
    },
    "catalog":{
        name:"Catalog",
        icon:"fa fa-store",
        list:[
            {name:"name",label:"Name",type:"input"},
            {name:"slug",label:"Slug",type:"input"},
            {name:"title",label:"Title",type:"input"},
            {name:"priority",label:"Priority",type:"input"},
            {name:"",label:"Shopping Cart",type:"fieldset"},
            {name:"add_button",label:"add item button",type:"input"},
            {name:"cart",label:"Activate shopping cart",type:"select",options:["on","off"]}
        ]
    },
    "gallery":{
        name:"Gallery",
        icon:"fa fa-store",
        list:[
            {name:"name",label:"Name"},
            {name:"slug",label:"Slug"},
            {name:"title",label:"Title"},
            ...listGenerator("Image", 9,{title:"Title"})  
        ]
        
    },
    "contact":{
        name:"Contact",
        icon:"fa fa-store",
        list:[
            {name:"name",label:"Name"},
            {name:"slug",label:"Slug"},
            {name:"title",label:"Title"}, 
            {name:"phones",label:"Phones"}
        ]
    },
    "address":{
        name:"Address",
        icon:"fa fa-store",
        list:[
            {name:"bizname",label:"Business name"},
            {name:"address",label:"Address"},
            {name:"city",label:"City"},
            {name:"state",label:"State"},
            {name:"zipcode",label:"Zipcode"},
            {name:"phone",label:"Phone"},
            {name:"fax",label:"Fax"},  
            {name:"email",label:"Email"}
        ]
    },
    "theme":{
        name:"Theme",
        icon:"fa fa-store",
        list:[
            {name:"logo",label:"Logo",type:"image"},
            {name:"cover",label:"Desktop Cover",type:"image"},
            {name:"tabletcover",label:"Tablet Cover",type:"image"},
            {name:"mobilecover",label:"Phone Cover",type:"image"},
            {name:"navBg",label:"Navbar Background",type:"image"},
            {name:"classLogo",label:"Logo Class", type:"select",options:["is-lg","is-sm","is-hidden"]} 
        ]
    },
    "book":{
        name:"Book",
        icon:"fa fa-book",
        list:[
            {name:"name",label:"Name"},
            {name:"slug",label:"Slug"},
            {name:"title",label:"Title"},
        ]
        
    }
};
const priority = listGeneratorNum(30);

export const app_fields = {
    home:{
        link:"settings/home",
        name:"Home",
        slug:"/",
        title:"Home",
        icon:"fa fa-home",
        content:"",
        style:"landing",
        locked:true, // will hide buttons
        type:"bg"
    },
    contact:{
        link:null,
        title:"Contact Form",
        icon:"fa fa-list",
        type:"public"
    },
    members:{
        link:null,
        title:"Members",
        icon:"fa fa-users",
        type:"bg"
    },
    adminsettings:{
        link:null,
        title:"Admin Settings",
        icon:"fa fa-user",
        type:"bg"
    },
    catalog:{
        link:null,
        title:"Catalog",
        icon:"fa fa-shopping-cart",
        name:"Catalog",
        slug:"catalog",
        type:"public",
        fields:{
            hide:{
                group_mods_slug:true,
                item_mods_image:true,
                item_mods_modifiers:true,
                item_mods_button:true,
                subgroup_items_choose:true
            },
            options:{
                group_type:["items","mods"],
                group_priority: priority,
                group_public:["yes","no"],
                subgroup_priority: priority,
                item_priority: priority,
                item_instock:["yes","no"],
            },
            groups:["name","description","image","type","public","priority","slug"],
            subgroups:["name","priority","choose"],
            items:["name","description","instock","image","price","priority","modifiers"],
            
            groupPreview:{
                name:"Group Name",
                description:"Goup Description",
                type:"items",
                state:"on",
                priority:34
            },
            subGroupPreview:{
                name:"Sub Group",
                items:"",
                priority:0,
                choose:1
            },
            itemPreview:{
                name:"Item",
                description:"Description",
                image:"",
                price:0.00,
                priority:34,
                instock:"yes",
                modifiers:"undefined",
            }
        },
        txt_create_group:"Create catalog group",
        txt_create_subgroup:"Create catalog subgroup",
        txt_create_item:"Create Item",
        txt_add_item:"Add Item",
        txt_items:"Items", 
        txt_subtitle:"Add Catalog Category",
        txt_subtitle_groups:"Groups ",
        txt_subtitle_subgroups:"SubGroups",
        txt_subtitle_items:"Category Item",
    },
    inbox:{
        link:null,
        title:"Inbox",
        icon:"fa fa-envelope",
        type:"bg"
   
    },
    pages:{
        link:null,
        title:"Pages",
        icon:"fa fa-file" ,
        type:"bg"
    },
    gallery:{
        link:'settings/gallery',
        title:"Gallery",
        icon:"fa fa-image",
        name:"Gallery",
        slug:"gallery",
        content:"",
        style:"gallery",
        type:"public"
   
    },
    settings:{
        link:null,
        title:"Settings",
        icon:'fa fa-wrench',
        type:"bg"
    },
    book:{
        link:null,
        title:"Book",
        icon:"fa fa-book",
        type:"public",
        locked:1
    }

}

