import React, { Component } from 'react';
import {join,setStorage,getStorage,Image} from '../../helper';
import {update,ref,set, getDatabase } from "firebase/database"
//import join from 'join-path';
//firebase,sortObj,pgText,Image,textCutter,queryParams
import {Link} from 'react-router-dom';
const db = getDatabase();
let text = "Hi";
class EditItemsPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            items:{},
            find:"",
            textvalue:"",
            path: join("users", this.props.user,"apps"),
        }
    }
  
    componentDidMount(){
        const store = getStorage();
       /* if(typeof this.props.data.pages.catalog !=="object"){
            const path = this.props.path +'/pages/catalog';
            set(ref(db,path), {
                name:"Catalog",
                cats:"", 
                groups:"",
                items:"",
                options:""}
            );
        }*/
        this.setState(store);
    }
    componentDidUpdate(){
        setStorage(this.state); 
    }
    openPromt(payload){
        this.props.dispatch({type:"OPEN_PROMPT",payload:payload});
    }
    isOption(strng){
        return strng.search('option')>=0?  true : false;
    }
    editItem(subpage,id, field, value){
        const paths = {
            cats:'pages/catalog/cats', 
            groups:'pages/catalog/groups', 
            items:'pages/catalog/items',
            options:'pages/catalog/options'};
        const path = join(this.props.path,paths[subpage],id, field);
        let payload = { 
            path:path,
            active:true,
            type: "input",
            name: field,
            choose:0,
            value: value
       };
       if(this.isOption(field)){
        console.log(field);
        payload = { 
            path:path,
            active:true,
            type: "select",
            name: field,
            options:this.GetOptions(),
            choose:1,
            value: value
        };
       }
       if(this.IsGroupField(field)){
            payload = { 
                path:path,
                active:true,
                type: "select",
                name: field,
                options:this.GetGroups(),
                choose:1,
                value: value
            };
       }
       this.props.dispatch({type:"OPEN_PROMPT",payload:payload});
       setTimeout(()=>{
            document.getElementById("focusField").focus();
       },100);
    }
    onClick(){
        const props = this.props;
        setTimeout(()=>{
            props.dispatch({type:"OPEN_PROMPT",payload:{pageChanged:true}});
        });
    }
    getValue(type, id, field){
        let steps =0;
        steps = typeof this.props.data.pages.catalog[type]=== 'object' ? 1: steps;
        if(steps === 1 ){
            if(this.props.data.pages.catalog[type].hasOwnProperty(id)){
               steps = 2;
            }
        }
        if(steps === 2 ){
            if(this.props.data.pages.catalog[type][id].hasOwnProperty(field)){
                return this.props.data.pages.catalog[type][id][field];
            }
        }
        return "";
    }
    keyGen(){
        return Math.random().toString(16).slice(2);
    }
    GetGroups(){
        let grps = {};
        if(this.props.data.pages.catalog.hasOwnProperty('groups')){
            const groups = this.props.data.pages.catalog.groups;
            {Object.keys(groups).map((group)=>{
                grps[group]= group.replace("group","G") + ": "+ groups[group].name + " [" + groups[group].clue+ "]" ;
            })}
        };
        
        return grps;
    }
    GetItems(){
        let grps = {};
        if(this.props.data.pages.catalog.hasOwnProperty('groups')){
            const items = this.props.data.pages.catalog.items;
            {Object.keys(items).map((item)=>{
                grps[item]= item+": "+ items[item].name ;
            })}
        };
        
        return grps;
    }
    GetOptions(){
        let grps = {};
        if(this.props.data.pages.catalog.hasOwnProperty('options')){
            const items = this.props.data.pages.catalog.options;
            {Object.keys(items).map((item)=>{
                grps[item]= item+": "+ items[item].name ;
            })}
        };
        
        return grps;
    }
    filterGrid(t){
        const k = document.getElementById("searchBox").value;
        this.setState({find:k});
    }
    IsGroupField(field){
        if(field.indexOf("group") >= 0){
            return true;
        }
        return false;
    }
  
    getPrice(val){
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });
          if(val ==="") return false;
          return formatter.format(val);
    }
    clearCatalog(){
        alert('This function is not available')
       /* alert("This function is disabled")
        const db = getDatabase();
        const path = this.props.path +'/pages/catalog';
        update(ref(db,path), {cats:"", groups:"", items:"",options:""}); */
    }
      
    FilterComparator(find, value){
        let count = 0;
        const splt = find.split(" ");
        splt.map(word =>{
            count = value.toLowerCase().includes(word.toLowerCase()) ? count+1 : count;
        })
        return count;
    }
    fieldName(field){
        let f = field.split("_");
        let num = f.length==1? "":parseInt(f[1])+1;
        field = `${f[0]} ${num}`
        field = field.toUpperCase();
        return field;
    }
    onFileChange(e){
        if(e.target.files[0].type ==="text/plain"){
            var reader = new FileReader();
            reader.onload = (event)=>{
               console.log(event.target.result);
            }
            reader.readAsText(e.target.files[0]);
        }else{
            alert("ERROR: SELECT A VALID .JSON FILE");
        }
       
    }
    catalogUpload(data){
       const lines = data.split('\n');
       var length = lines.length;
       let group = "";
       let items = {};
       let count = 0;
       for(var i = 0; i < length; i++){
            const l = String(lines[i]).replace('\t','').replace('\r','');
            const cmd = l.split("_");
            if(cmd[0]==='ITEMS'){
                // is command
                group=cmd[1];
            }else{
                // is item
                if(l==="END"){
                    //console.log(items);
                    const path = this.props.path +'/pages/catalog';
                    console.log(items)
                    update(ref(db,path), {items:{}});
                    update(ref(db,path), {items});
                }else{
                    const lc =String(l).replace('\t','').replace('\r','');
                    const item = lc.split('@');
                    const subname = (count <=9)? "P00":"P0"
                    const id = `${subname}${count}`;
                    if(items[0]==="" || l==="") return false;
                    items[id] = {
                        "name":item[0],
                        "description":item[1],
                        "price":item[2] || "",
                        "image":"https://shorturl.at/7R4fl",
                        "group":group
                    };
                    count++;
                }
            }
        }
    }
    upload(e){
        if(e.type==="change"){
            const file = e.target.files[0];
            if(file.type==="text/plain"){
                var reader = new FileReader();
                reader.onload = (event)=>{
                    var data = event.target.result;
                    this.setState({textvalue:data});
                    e.target.value = "";
                    this.catalogUpload(data);
                }
                reader.readAsText(e.target.files[0]);
            }else{
                alert("FILE NOT SUPPORT");
            }
        }
    }
    MenuComp(){
        const path = "/"+ this.props.app + "/catalog/";
        const subpage = this.props.subpage;
        const groups = this.GetGroups();
        const options = this.GetOptions();
        var GridComp = [];
        var limited = {cats:50, groups:50, items:200,options:100}
        var subname = {cats:"C0",groups:"G0", items:"P0",options:"S0"}
        var fields = {
            cats:['ID','name','description','slug','group_0','group_1','group_2','group_3','group_4','group_5','group_6','group_7','group_8','group_9','group_10','group_11','image'],
            groups:['ID','name','description',,'clue'],
            items:['ID','name','description','group','option_0','option_1','option_2','option_3','price','sale_price','upc','stock','image'],
            options:['ID','name','group','min','max']
        }
        let i = 0;
        let even = 0;
        const GridFilter= ()=>{
            let count = 0;
            const row = <div className={even? "grid even":"grid odd"}>
                {fields[subpage].map(field=>{
                    const idd = i <= 9? `0${i}`:i;
                    const id = `${subname[subpage]}${idd}`;
                    const value = this.getValue(subpage, id , field);
                    count += this.FilterComparator(this.state.find, value +" " + groups[value]);
                    switch(field){
                        case 'ID':
                            return <div key={this.keyGen()} className="grid-item"><b>{id}</b></div>
                        break;  
                        case 'price':
                            return <div key={this.keyGen()} onClick={this.editItem.bind(this,subpage,id, field, value)} className="grid-item"><b>{this.getPrice(value)}</b></div>
                        break;
        
                        default:
                            return <div key={this.keyGen()} onClick={this.editItem.bind(this,subpage,id, field, value)} className="grid-item">
                            {this.IsGroupField(field)? groups[value] : 
                                field==="image" && value? 
                                    <img style={{maxWidth:"40px"}}src={Image(value)}/>:
                                this.isOption(field)? options[value]:value}
                            </div>
                        break;
                    }

                })}
            </div>
            even = even? 0: 1;
            if(count > 0) { return row; } else { return null}  
        } 
        for(i =0; i < limited[subpage]; i++){
            GridComp.push(<div key={this.keyGen()} >
                {i===0?<div className="grid is-header">
                    {fields[subpage].map(id=>{
                        return <div key={this.keyGen()}  className="grid-item">{this.fieldName(id)}</div>
                    })}
                </div>:null}
                {GridFilter(this.state.find)}
            </div>);
        }
    
        return <div>
            <div className="catalogItems">
            <h1 className="title is-vertical-one">Catalog {this.props.subpage} </h1>       
            <Link  className={subpage==="cats"?"button is-selected is-small":"button"+" is-small"}  to={path + "cats"} onClick={this.onClick.bind(this)}>
                    <span className='icon'>
                        <i className="fa fa-utensils"></i>
                    </span> 
                    <span>Categories</span>
             </Link>
             <Link className={subpage==="groups"?"button is-selected is-small":"button"+" is-small"}  to={path + "groups"} onClick={this.onClick.bind(this)}>
                    <span className='icon'>
                        <i className="fa fa-list"></i>
                    </span> 
                    <span>Groups</span>
             </Link>
             <Link className={subpage==="items"?"button is-selected is-small":"button"+" is-small"} to={path + "items"} onClick={this.onClick.bind(this)}>
                    <span className='icon'>
                        <i className="fa fa-burger"></i>
                    </span> 
                    <span>Items</span>
             </Link>
             <Link className={subpage==="options"?"button is-selected is-small":"button"+" is-small"} to={path + "options"} onClick={this.onClick.bind(this)}>
                    <span className='icon'>
                        <i className="fa fa-list"></i>
                    </span> 
                    <span>Options</span>
             </Link>
             <Link className={subpage==="info"?"button is-selected is-small":"button"+" is-small"} to={path + "info"} onClick={this.onClick.bind(this)}>
                <span className='icon'>
                    <i className="fa fa-info" alt="Click to get info how it work"></i>
                </span> 
             </Link>
             <Link className={subpage==="upload"?"button is-selected is-small":"button"+" is-small"} to={path + "upload"} onClick={this.onClick.bind(this)}>
                <span className='icon'>
                    <i className="fa fa-upload" alt="Upload"></i>
                </span> 
             </Link>
             <input className="button is-small" defaultValue={this.state.find} id="searchBox" style={{width:"200px"}} type="input"/>
             <div  className="button is-small" to="#" onClick={this.filterGrid.bind(this)}>
                <span className='icon'>
                    <i className="fa fa-search"></i>
                </span> 
             </div>
             <div  className="button is-small" to="#" onClick={this.clearCatalog.bind(this)}>
                <span className='icon'>
                    <i className="fa fa-trash"></i>
                </span> 
             </div>
            </div>
            <div></div>
            {this.state.find? <div><b>Search for:</b> {this.state.find}</div> :null }
            <div>
                {GridComp}
                {subpage==="info"?<div>
                    <b>Caterogories:</b> You can use it for example Sushi,also add description<br/>
                    <b>Group:</b> For example Raw-sushi - or cooked sushi, also you can use it to group the options for each item. example combo 1, combo 2 etc<br/>
                    <b>items:</b> You product example California Roll 4.99 you can add picture set the a group, add options<br/>
                    <b>Options:</b> Options is very important you can add variation to you item or multiple items.<br/>
                    <input type="file" onChange={this.onFileChange}/>
                </div> :null}
                {subpage==="upload"?<div>
                    <h2>Upload Catalog</h2>
                    <div  className="button is-small" to="#" onClick={this.upload(this)}>
                        <input type="file" onChange={(e)=> this.upload(e)}/>
                        
                    </div>
                    <pre>{this.state.textvalue}</pre>
                </div> :null}
            </div>
        </div>
    }
 
    render(){
       if(typeof this.props.data.pages.catalog ==="object"){
            return <div className="catalog-section">
                {this.MenuComp()}
                </div>
       }else{
            return <div className='section'>
                <h1>Create a catalog ?</h1>
            </div>
       }
    }
}
export default EditItemsPage;
