/*
LOG
- Pages not working because firebase was updated to version 9
- need to work on this
*/
import React, { Component } from 'react';
import {pgText,get,join,isAdmin} from '../helper';
import {Link} from 'react-router-dom'
// import action components
import EditCatalogPage from './actions/EditCatalogPage'
import EditContentPage from './actions/EditContentPage'
import EditInboxPage from './actions/EditInboxPage'
import EditContactPage from './actions/EditFormPage'
// ADMIN ONLY
import EditMembersPage from './actions/EditMembersPage'
import AdminSettings from './actions/AdminSettings'
// EDN ADMIN ONLY
import EditApps from './actions/EditApps.js';
import EditAppSettings from './actions/EditAppSettings.js';
/** HOW TO ADD MORE APPS 
 * - Add your components below
 * - Add Page in Pages Switcher
 * - Edit Json on helper/fields.js edit app_settings_fields && app_fields 
 * - you have to add to app_fields type public if you will use in front endt, bg if you only need for backend
 
 
 * **/
import EditBook from './actions/EditBook'
class Pages extends Component {
    render(){
        if(!this.props.settings.username) 
            return <div>Please create a username, can't manager you page without one</div>
        // show pages to select
        if(!this.props.app) {
            return <EditApps settings={this.props.settings} data={this.props.data} user={this.props.user}/>;
        }
        if(this.props.page){
            let props = { ...this.props};
                props.data = get(this.props,`data.pages.${this.props.page}`,{});
                props.items = {};
            /* PAGES SWITCHER */
            // TO ADD NEW PAGE ALSO REGISTER IN helpers/fields.js
            switch(this.props.page){
                case 'settings':
                    return <EditAppSettings {...this.props}/>;
                case 'catalog':
                    return <EditCatalogPage {...this.props}/>;
                case 'members':
                    return <EditMembersPage {...this.props}/>;
                case 'adminsettings':
                        return <AdminSettings {...this.props}/>;
                case 'book':
                    return <EditBook {...this.props}/>;        
                case 'contact':
                    return <EditContactPage {...this.props}/>;
                case 'pages':
                    return <EditContentPage {...this.props}/>;
                case 'inbox':
                    return <EditInboxPage {...props}/>
                default:
                    return <div><h1>404</h1> NOT FOUND</div>
            }
        }else{
            const pages = Object.keys(pgText);
            return <div className="section">
                <h1 className="title is-1">Desktop</h1>
                {pages.map((key, i)=>{
                    const item  = pgText[key];
                    const className = item.icon;
                    // Pages to restrict from Users only Admin can access
                    if(!isAdmin(this.props.settings) && key==="members") return false;
                    if(!isAdmin(this.props.settings) && key==="adminsettings") return false;


                    let link = item.link!==null? join(`${this.props.app}`,item.link) : join(this.props.app,key,key ==="catalog"?"items":"");
                    return <Link key={i} className="icon-pages" to={link}>
                        <i className={className}></i> 
                        <span>{pgText[key].title}</span>
                     </Link>
                })}
            </div>;
        }
    }
}
export default Pages