import React, { Component,useRef } from 'react';
import {join, firebase, createGroups, Image,deleteMediaData,saveMediaData} from '../../helper';
import { getStorage, ref ,uploadBytesResumable, deleteObject} from "firebase/storage";

export default class EditMediaItems extends Component {
    constructor(props){
        super(props);
        this.state = {
            files:null,
            progress:0,
            justUploaded:false,
            delete:null,
        }
    }
    onChange(e){
        const files = e.target.files;
        if(files){
            this.setState({files:e.target.files[0]});
        }
    }
   
    delete(key, original,  thumbnail){
        const user =  this.props.user;
        const storage = getStorage();
        const hqRef= ref(storage, `media/${original}`);
        const lqRef = ref(storage, `media/${thumbnail}`);
        deleteObject(hqRef)
        deleteObject(lqRef) 
        deleteMediaData(user, key);
    }
    validType(type, types){
        let ex = false;
        let i=0;
        types.map(tp =>{
            ex = type === tp? true : ex;i++;
        });
        console.log(type)
        return ex;
    }
    extCheck(type, types, ext){
        let ex = null;
        let i=0;
        types.map(tp =>{
            ex = type === tp? ext[i] : ex;i++;
        });
        return ex;
    }
    upload(){
    
        if(this.state.files) {
            const limit = 3500;
            const files = this.state.files; // just one for now
            const username = this.props.settings.username;
            const valid_types = ["image/png","image/jpeg","image/svg+xml","application/pdf"];
            const file_ext = [".png",".jpg",".svg",".pdf"];
            if(files.size < limit){
                alert("ERRO: FILE TO BIG!");
                return false;
            }
            if(this.validType(files.type,valid_types)){
                this.setState({progress:1});
                this.setState({uploading:1}); // PUT IN MODE UPLOADING
                let ext = this.extCheck(files.type, valid_types, file_ext);
                const tiempo = Math.floor(Date.now() / 1000);
                const bucket = "media";
                const filename = `${username}-${tiempo}${ext}`; /* CHILD NAME */
                const thumbnail = `${username}-${tiempo}_600x400${ext}`;  
                const imgData = {src:filename, name: files.name, thumbnail: ext===".svg"? filename: thumbnail};
                const that = this;
                /* UPLOAD PRECESS */
            
                const storage = getStorage();
                const sRef = ref(storage,bucket + "/" +filename);
                const metadata = {contentType: files.type};
                const uploadTask = uploadBytesResumable(sRef, files, metadata);
                uploadTask.on('state_changed',
                    (snapshot)=>{
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        if(progress ===100){ 
                            setTimeout(function(){
                                saveMediaData(that.props.user,imgData);
                            },2000);
                        }
                    });
            }else{
                this.setState({progress:0});
                alert(files.name +' FILE FORMAT NOT VALID');
            }
        } 
    }
    browserFile(){
        document.getElementById('_FILE_').click();
    }
    cancel(){
        this.setState({delete:null});
    }
    willdelete(key){
        this.setState({delete:key}); 
    }
    render(){
        const groups = createGroups(this.props.media,3);
        return <div className="section is-white">
            <div className="columns">
                
                <div className="column">
                    {this.state.progress === 1?  
                        <div className='wait'>
                        <div className="text">Uploading</div>
                        <div className="dots">
                            <div className="dot1 bounce"></div>
                            <div className="dot2 bounce"></div>
                            <div className="dot3 bounce"></div>
                        </div>
                    </div>
                
                    :
                    <div>
                        <div className="field has-addons">
                            <div className="control">
                                <button onClick={()=>{this.props.dispatch({type:"MEDIA_TOGGLE"})}} className="button">Cancel </button>
                                <input  id="_FILE_" type="file" name="file" onChange={this.onChange.bind(this)} style={{display: "none"}}/>
                            </div>
                            <div className="control">
                                <a className="button" onClick={this.browserFile.bind(this)}>  
                                    <span className="icon is-small">
                                        <i className="fas fa-image"></i>
                                    </span>
                                    <span> {this.state.files? this.state.files.name : "Choose File"} </span>
                                </a>
                            </div>
                            {this.state.files?
                                <div className="control">
                                    <button className="button is-success" onClick={this.upload.bind(this)}>Upload</button>
                                </div>
                                :null}
                        </div>
                    </div>
                    }
                </div>
                
            </div>
            <div className="columns">
            {Object.keys(groups).map((key,i)=>{
                const group = groups[key];
                return <div className="column" key={i}>
                    {Object.keys(group.items).map((item, j)=>{
                        let img = group.items[item];
                        // this function is because thumbnail take like 5 second to be created
                        const style = this.props.media_select===true? {cursor:"pointer"}:{};
                        return <div className="notification media-image" key={j}>
                                <div className="image-wrap">
                                <img style={style}
                                     alt={img.name} 
                                     src={Image(img.thumbnail)}
                                     onClick={()=> this.props.media_select===true ? this.props.dispatch({type:"MEDIA_TOGGLE",media_selected: true, media_image:img.src}) : null } title={img.name} />
                                </div>
                                <input type="disabled" defaultValue={Image(img.src)}/>
                                {this.state.delete ===item?<div>
                                    <div className="is-bold">Are you sure you want to delete this?</div>
                                    <a className="button is-small" onClick={this.cancel.bind(this)}>
                                        <span className="icon">
                                            <i className="fas fa-times"></i>
                                        </span>
                                        <span>Cancel</span>
                                    </a>
                                    <a className="button is-danger is-small" onClick={this.delete.bind(this, item, img.src, img.thumbnail)}>
                                        <span className="icon">
                                            <i className="fas fa-trash"></i>
                                        </span>
                                        <span>Delete</span>
                                    </a>
                                   
                                </div>
                                :
                                <div>
                                    <a className="button is-danger is-small" onClick={this.willdelete.bind(this, item, img.src, img.thumbnail)}>
                                        <span className="icon">
                                            <i className="fas fa-trash"></i>
                                        </span>
                                    <span>Delete</span>
                                </a>
                                </div>
                                }
                            </div>
                    })}
                </div>
            })}
            </div>
        </div>

    }
}

