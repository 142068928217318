import React, { Component } from 'react';
import {join,firebase,pgText,appSettingsFields,getParam,Image,onImageError} from '../../helper';
import {Link} from 'react-router-dom'
//import join from 'join-path';
const fields = appSettingsFields;
const {set, db, ref} = firebase.database;

class EditAppSettings extends Component {
    constructor(props){
        super(props);
        this.state = {
            form:{},
            saveBtn :false
        }
    }
    saveToDatabase(path, payload){
        set(ref(db, path),payload);
    }
    
    saveSettings(){
        let settings = this.state.form;
        const pg = this.props.subpage;
        let Fields = {}
            Fields.list = [];
        //Fields
        fields[pg].list.map((f, i)=>{
            Fields.list.push(f);
            if(Array.isArray(f.fields)){
                f.fields.map(ff =>{
                    Fields.list.push(ff);
                    return null;
                });
            }
            return null;
        });
        Fields.list.map((field, i)=>{
            const type = Fields.list[i].type;
            if(type !== "fieldset"){
                const name = Fields.list[i].name;
                let public_path = join('username',this.props.settings.username,'settings')
                let private_path = join(this.props.path, "settings");
                settings[name] = this.refs[name].value;
                if(i===0){
                   //this.cleanDatabase(public_path);
                   //this.cleanDatabase(private_path);
                }
                if(typeof Fields.list==="object"){
                    // this code will bring some page settings to setttings 
                    switch(this.props.subpage){
                        case 'theme':
                            public_path = join(public_path, pg);
                            private_path = join(private_path, pg);
                        break;
                        case 'address':
                            public_path = join(public_path, pg);
                            private_path = join(private_path, pg);
                        break;
                        
                        default:
                            public_path = join(public_path, "pages", pg);
                            private_path = join(private_path, "pages", pg)
                    }
                }else{
                    console.log(fields.list)
                }
                if(i === (Fields.list.length -1)){
                    this.saveToDatabase(public_path, settings);
                    this.saveToDatabase(private_path, settings);
                    this.setState({saveBtn:true});
                    alert("Saved!");
                }
            }
            return null;
        });
    }
    isChild(){
        return (typeof fields[this.props.subpage] ==="object")? true : false;
    }
    formData(subpage){
        if(!subpage) return false;
        let form = this.props.data.settings || {};
        
        if(subpage ==="theme" || subpage ==="address"){
            form = form[subpage] || {};
        }else{
            if(!form.pages[subpage]){
                /* FIXED ERROR FOR NEW APP */
                form.pages[subpage] = {title: subpage, slug: subpage, name: subpage };
            }
            form = form.pages[subpage];
        }
        if(typeof form ==="object" && (Object.keys(form).length > 0)){
            this.props.dispatch({type:"UPDATE_FORM",form: form});
        }
    }
    UNSAFE_componentWillMount(){
        const subpage = getParam("subpage");
        this.formData(subpage);
    }
    onClick(field){
        const subpage = getParam("subpage");
        if(field !== subpage){
            this.formData(field);
        }
    }
       
    render(){
        const values = this.props.form || {};
        const saveBtnClass = this.state.saveBtn? "button is-rounded is-success": "button is-rounded is-primary";
        const saveBtnText = this.state.saveBtn? "Saved":"Save Changes";
        const SettButtons = (props)=>{
            const pg = this.props.subpage;
            return <p className="buttons">
                {Object.keys(fields).map((field, i)=>{
                    const to =join(this.props.app,"settings",field);
                    return <Link to={to}  key={i} onClick={()=>{this.onClick(field)}} className={field===pg? "button is-active":"button"} >{fields[field].name}</Link>
                })}</p>
        }
        if(!this.isChild()){
            return <div className="section">
            <h1 className="title is-1" >{pgText.settings.title}</h1>
                <SettButtons />
            </div>
        }
        return <div className="section">

            <h1 className="title is-1" >{pgText.settings.title}</h1>
            <SettButtons />
            {fields[this.props.subpage].list.map((field, i)=>{
                const value = values[field.name];
                switch(field.type){
                    case 'select':
                        return <div className="field" key={i}>
                            <label className="label"> {field.label} ({value}) </label>
                            <div className="select">
                                <select defaultValue={value} name={field.name} ref={field.name}>
                                    {field.options.map((option =>{
                                        return <option key={option} value={option}> {option} </option>
                                    }))}
                                </select>
                            </div>
                        </div>
                    case 'fieldset':
                        return <div key={i} className="field-group">
                            <h2 className="title is-5">{field.label}</h2>
                        </div>;
                    case 'image':
                         return <div className="notification" key={i}>
                            <div className="columns">
                                <div className="column">
                                    <label className="label">{field.label}</label>
                                    <div className="image-wrap">
                                        <img alt={field.label} onError={onImageError.bind(this)} src={Image(value)} width="100%"/>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field has-addons">
                                        <div className="control is-expanded">
                                            <input ref={field.name} defaultValue={values[field.name]} className="input" />
                                        </div>
                                        <div className="control">
                                            <a className="button is-success" onClick={()=>this.props.dispatch({type:"MEDIA_TOGGLE",media_target:field.name,media_select:true})}>Choose</a>
                                        </div>
                                    </div>
                                    {Array.isArray(field.fields)? field.fields.map((f)=>{
                                        return  <div className="field" key={f.name}>
                                            <label className="label">{f.label}</label>
                                            <div className="control">
                                                <input ref={f.name} defaultValue={values[f.name]} className="input" />  
                                            </div>
                                        </div>
                                    }):null}
                                </div>
                            </div>
                        </div>
                    default:
                        return <div className="field" key={i}>
                            <label className="label">{field.label}</label>
                            <div className="control">
                                <input ref={field.name} defaultValue={values[field.name]} className="input" />  
                            </div>
                        </div>
                }
            })}
            <div className="field">
                <div className="control">
                    <button onClick={this.saveSettings.bind(this)} className={saveBtnClass}>{saveBtnText}</button>
                </div>
            </div>
        </div>
    }
}
export default EditAppSettings;