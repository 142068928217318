import React, { Component } from 'react';
import {join,firebase,get,Image,backup,isAdmin} from '../../helper';
import {Link} from 'react-router-dom';
const {set, db, ref, onValue} = firebase.database;
//import join from 'join-path';

const dataSchema = {settings:{path:''}}
class EditApps extends Component {
    constructor(props){
        super(props);
        this.state = {
            items:[],
            dataFromFile:null,
            path: join("users", this.props.user,"apps"),
            fetched:false
        }
    }
    uploadTemplate(app){
        // THIS NEED TO BE UPDATED
        set(ref(db, `users/${this.props.user}`,this.state.dataFromFile)).onComplete((e)=>{
            console.log(e);
        });
        firebase.database().ref(`users/${this.props.user}`).update(this.state.dataFromFile).then((e)=>{
            this.setState({dataFromFile:null});
        }); 
    }
    preloadFile(e){
        if(e.target.files[0].type ==="application/json"){
            var reader = new FileReader();
            reader.onload = (event)=>{
                var data = JSON.parse(event.target.result);
                if(Object.keys(data).length===2){
                    this.setState({dataFromFile: data });
                }
            }
            reader.readAsText(e.target.files[0]);
        }else{
            alert("ERROR: SELECT A VALID .JSON FILE");
        }
    }
    deleteApp(id){
        alert("DELETE IS NOT AVAILABLE")
        //return false;
        //const path = join(this.state.path, id);
        //firebase.database().ref(path).remove();

    }
    backupCatalogApp(){
        return onValue(ref(db,`users/${this.props.user}`), (snapshot) => {
            let val = snapshot.val();
            delete val.settings;
            backup(val, this.props.settings.username);
            
          }, {
            onlyOnce: true
          });
    }
    createApp(){

        firebase.database().ref(this.state.path).push(dataSchema);
    }

    render(){
        const settings = this.props.settings;
        const apps = (this.props.data)? this.props.data : {};
        const hasPages = (app)=>{
            return typeof this.props.data[app].pages ==="object" ? true : false;
        }
       if(!Object(apps)) return false;
        return <section className="section">  
            <h1 className="title is-1">Workstation</h1>
            {isAdmin(settings)?<h3 className="wellcome">Wellcome Admin ☆</h3>:""}
            <div className="columns">
            {Object.keys(apps).map((id, key)=>{
                const app = apps[id];
                const apptitle =get(app,'settings.address.bizname','Your Biz Name');
                const logo =get(app,'settings.theme.logo','Your Biz Name');
                return <div className="column" key={key}>
                    <div className="notification is-white has-border">
                    <div className="columns">
                        <div className="column">
                           <img src={Image(logo)} className="app-logo" alt={apptitle}/>
                        </div>
                        <div className="column">
                            {hasPages(id)?
                                <p className="buttons">
                                    <Link className="button" to={"/" + id}>Edit site</Link>
                                    <a target="_blank" href={"https://" + settings.website} className="button is-success">View site</a>
                                    <a className="button is-link"  onClick={this.backupCatalogApp.bind(this)}>Backup</a>
                                </p>: 
                                <div className="buttons"></div>
                            }
                        </div>
                    </div>
                    </div>
                </div>
            })}  
            </div>
        </section>
       
    }
}
export default EditApps;