import React, { Component } from 'react';
import {firebase,pgText, isAdmin} from '../../helper';
const {set, db, ref,  onValue, update} = firebase.database;

let _uploadVersion = 10;
class EditMembers extends Component {
    constructor(props){
        super(props)
        this.state = {
            users: {},
            dataFromFile:{},
            openuser:null,
            form:null
        };
        this.addNewUserById = React.createRef();   
    }
    componentDidMount(){
        this.loadUsers();
    }
    loadUsers(){
        onValue(ref(db,'users'), (snapshot) => {
             const val = snapshot.val();
             this.setState({users:val});
        }, {
         onlyOnce: true
        });
    }
    getAppId(apps){
        let appId =null;
        {Object.keys(apps).map((key)=>{
            appId = key;
        })}
        return appId;
    }
    backup(e,username){
        let udata = {};
        udata["apps"] = e.apps;
        udata["media"] = e.media;
        udata["backupVersion"] = _uploadVersion;
       
        const uname = username;
        const blob = new Blob([JSON.stringify(udata, null, 2)], {
        type: 'application/json',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${username}.json`;
        a.click();
        URL.revokeObjectURL(url);
    }
    upload(uid){
        let dff = {};
            dff = this.state.dataFromFile;
            dff["settings"] = this.state.users[uid].settings;
            
            const path = 'users/' + uid;
        if(dff.backupVersion===10){
            delete dff.backupVersion;
            set(ref(db,path), dff);
            this.setState({dataFromFile: {}});
            alert("UPLOADED COMPLETED!");
        }else{
            alert("NOT VALID FILE!");
        }
           
    }
    preloadFile(e){
        if(e.target.files[0].type ==="application/json"){
            var reader = new FileReader();
            reader.onload = (event)=>{
                let data = JSON.parse(event.target.result);  
                this.setState({dataFromFile: data });
            }
            reader.readAsText(e.target.files[0]);
        }else{
            alert("ERROR: SELECT A VALID .JSON FILE");
        }
    }

     
    isEmpty(obj) {
        for (const prop in obj) {
          if (Object.hasOwn(obj, prop)) {
            return false;
          }
        }
      
        return true;
    }
    cancel(){
        this.setState({dataFromFile:{}});
    }
    openUser(params){
        const id = params.id;
        const action= params.action;
        console.log(params);
        if(id===this.state.openuser && action==="close"){
            this.setState({openuser:null,form:null});
        }else{
            this.setState({openuser:id});
        }
    }
    EditUserInfo(params){
        this.setState({form:null});
        // refresh first
        this.setState({form:params});
 
    }
    onChange(e){
        const value = e.target.value;
        let form = this.state.form || {};
        form["newValue"] = value; 
        this.setState(form);
    }
    addNewUser(){
        const id = this.addNewUserById.current.value;
        const path= `users/${id}/settings`;
        const settingsFields = {
            fullname:"NewUser",
            username:"newuser",
            website:"newuser",
            pin:"newuser",
            rank:"user"
        }
        if(id.length > 0){
            update(ref(db, path), settingsFields);
            this.props.dispatch({type:"OPEN_PROMPT",payload:null});
        }else{
            alert("INPUT IS EMPTY!");
        }
    }
    updateUser(id){
        const form = this.state.form;
        const users = this.state.users;
        let fields = {};
        let path = `users/${id}/settings`;
        if(form.newValue!==""){
            fields[form.field] = form.newValue;
            update(ref(db,path), fields);
            users[id].settings[form.field] = form.newValue || "";
            this.setState({users:users,form:null});
        }
       
    }
    
    render(){
         // Start listing users from the beginning, 1000 at a time.
        if(!isAdmin(this.props.settings)) return <h1 className="title is-center">NO ACCESS</h1>
        const users = this.state.users || {};
            return <div className="section">
                <h1 className="title is-1">Members</h1>
                <div>
                    <h1 className="title">CREATE A NEWUSER BY ID: </h1>
                    <input className="button" ref={this.addNewUserById} type="input"/>
                    <input type="button" defaultValue="ADD" onClick={this.addNewUser.bind(this,'new')} className="button"/>
                               
                </div>
                <div>{Object.keys(users).map((id)=>{
                    const udata = users[id];
                    const u = users[id].settings;
                    if(this.state.openuser!==id && this.state.openuser!== null) return null;
                    //const wid = this.getAppId(users[id].apps);
                    return <div className="has-border"key={id}>
                        {id!==this.state.openuser?<div className="column">
                            <h2  onClick={this.openUser.bind(this,{id:id, action:"open"})}  className="title is-4 is-pointer">{u.fullname}</h2>
                        </div>:
                        <div className="columns">
                            <div className="column">
                                <h2 className="title is-pointer">{u.fullname}</h2>
                                <p className="is-bold">FULLNAME:</p>
                                <input type="button" value={u.fullname} className="button" onClick={this.EditUserInfo.bind(this,{field:"fullname",value:u.fullname})}/>
                                <p className="is-bold">USERNAME:</p>
                                <input type="button" value={u.username} className="button" onClick={this.EditUserInfo.bind(this,{field:"username",value:u.username})}/>
                                <p className="is-bold">WEDSITE:</p>
                                <input type="button" value={u.website} className="button" onClick={this.EditUserInfo.bind(this,{field:"website",value:u.website})}/>
                                <p className="is-bold">ID:</p>
                                <p className="userId">{id}</p>
                            </div>
                            <div className="column">
                                <p className="closeWindow" onClick={this.openUser.bind(this,{id:id, action:"close"})} ><i  class="fa fa-window-close" aria-hidden="true"></i></p>  
                                {this.state.form!==null?
                                    <div className="editField">
                                        <p><strong>Edit {this.state.form.field}:</strong> {this.state.form.value} </p>
                                        <input type="input" onChange={this.onChange.bind(this)} className="button"/>
                                        <input type="button" defaultValue="Save" onClick={this.updateUser.bind(this,id)} className="button"/>
                                </div>:
                                <div>
                                    <button className="button success" onClick={this.backup.bind(null,udata,u.username)}>Backup userdata</button>
                                    <br />
                                    <br />
                                    <h3 className="title is-4">UPLOAD A BACKUP</h3>
                                    {this.isEmpty(this.state.dataFromFile)?
                                        <input className="button" type="file" onChange={this.preloadFile.bind(this)}/>:
                                        <div>
                                            <button className="button" onClick={this.cancel.bind(this,id)}>Cancel</button>
                                            <button className="button success" onClick={this.upload.bind(this,id)}>Upload</button>
                                        </div>
                                    } 
                                </div>}  
                            </div>
                        </div>}
                    </div>
                })}</div>
         </div>
}
}
export default EditMembers;